import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop, Button } from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import UseCaseSider from "../../components/useCases/use-cases-sider";

const UseCaseTravelPlanner = ({}) => (
  <div>
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Online Travel Planner - DayViewer</title>
        <meta
          name="description"
          content="DayViewer is an ideal travel planner for your trips and journeys, drop all your information on your trip in one place and reduce stress associated with planning your travels."
        />
      </Helmet>
      <CommonHeader />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <UseCaseSider />

        <Layout className="guide-body">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>DayViewer As a Online Travel Planner</h1>

            <Button className="homepage-signup-btn">
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/signup", "_self")
                }
              >
                Sign Up
              </a>
            </Button>
          </div>

          <Row>
            <div>
              <h2>Plan Your Trip Online In One Place</h2>
            </div>
            <Col>
              <p>
                Wouldn't it be great if you had one reliable system where you
                could drop all your travel information into - and be able to
                refer to it anytime.
              </p>
              <p>
                You can upload copies of boarding passes, and all the other bits
                of information into calendar entries and notes. This information
                would then be to hand, within a couple of clicks whenever you
                need it (at the airport for example!)
              </p>
              <p>
                DayViewer doesn't plan the trip for you, you would still
                research and find out what you wish to do, but we do make it
                easier for you to collate the information and keep it in one
                place so you can access it easily, whenever required.
              </p>

              <p>
                Simply put all travel information in DayViewer and then to each
                related entry, tie them up with a label. The label manager would
                then make it super easy to organize and view all the information
                related to your trip
              </p>

              <p>
                Imagine you were planning a trip to New York. You would likely
                need to catch a plane there and back, book a hotel or other
                accommodation, organize transport and transfers, and plan your
                sightseeing or other adventures! - Then label all the related
                information "New York", and it's easier to filter/search.{" "}
              </p>
              <div
                style={{
                  textAlign: "center",
                  padding: 20,
                }}
              >
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/contentslides/online-trip-planner.png"
                  alt="online travel planner"
                  title="online travel planner"
                />
              </div>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default UseCaseTravelPlanner;
